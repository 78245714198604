import React from "react";
import "./About.css";
import profile from "../../images/profile.jpg";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import { AboutData } from "../../parseJson";

function About() {
  return (
    <section className="main-section container-fluid" id="about">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-align-center">
            <div className="heading-container">
              <p className="heading-subtitle">{AboutData.subtitle}</p>
              <h2 className="heading-title">{AboutData.title}</h2>
            </div>
          </div>
        </div>
        <div className=" main-section-content about__info-section">
          <div className="row align-items-center">
            <div className="col-12 col-lg-5 text-center text-lg-left">
              <img
                className="img-fluid info-profile"
                src={profile}
                alt="profile"
              />
            </div>
            <div className="col-12 col-lg-7 info-container">
              <div className="info-content">
                <h2 className="content-subtitle">
                  {AboutData.content.subtitle}
                </h2>
                <h6 className="content-title">{AboutData.content.title}</h6>
                <div className="content-description">
                  <p>{AboutData.content.description}</p>
                </div>
                <address className="address-content">
                  <div className="row">
                    <div className="address-info col-12 col-md-6">
                      <span>Name:</span>
                      <p>{AboutData.content.address.name}</p>
                    </div>
                    <div className="address-info col-12 col-md-6">
                      <span>Live:</span>
                      <p>{AboutData.content.address.live}</p>
                    </div>
                  </div>

                  <div className="row">
                    <div className="address-info col-12 align-items-center">
                      <span>Email:</span>
                      <p>
                        <a href={"mailto:" + AboutData.content.address.mail}>
                          {AboutData.content.address.mail}
                        </a>
                      </p>
                    </div>
                  </div>
                </address>

                <div className="row d-flex justify-content-center">
                  <div className="d-block d-sm-flex align-items-center">
                    <ul className="social-icon-list list-unstyled list-inline">
                      <li className="list-inline-item">
                        <a
                          href={AboutData.content.icon.instagram}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i>
                            <FaInstagram />
                          </i>
                        </a>
                      </li>
                      <li className="list-inline-item">
                        <a
                          href={AboutData.content.icon.linkedin}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i>
                            <FaLinkedin />
                          </i>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default About;
