import React, { useState } from "react";
import "./ContactForm.css";
import axios from "../../axios";

import emailjs from "@emailjs/browser";

function ContactForm() {
  const [formdata, hanldeFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
    sent: false,
    loading: false,
    error: false,
  });

  const handleName = (e) => {
    hanldeFormData({ ...formdata, name: e.target.value });
  };

  const handleEmail = (e) => {
    hanldeFormData({ ...formdata, email: e.target.value });
  };

  const handleSubject = (e) => {
    hanldeFormData({ ...formdata, subject: e.target.value });
  };

  const handleMessage = (e) => {
    hanldeFormData({ ...formdata, message: e.target.value });
  };

  const resetForm = () => {
    hanldeFormData({
      name: "",
      email: "",
      subject: "",
      message: "",
      sent: false,
      loading: false,
      error: false,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    hanldeFormData({
      ...formdata,
      loading: true,
    });
    // Sending the email using EmailJS
    emailjs
      .send(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        {
          from_name: formdata.name,
          from_email: formdata.email,
          subject: formdata.subject,
          message: formdata.message,
        },
        process.env.REACT_APP_PUBLIC_ID
      )
      .then(
        (response) => {
          hanldeFormData({
            ...formdata,
            sent: true,
            error: false,
            loading: false,
          });
          setTimeout(() => resetForm(), 5000);
        },
        (error) => {
          hanldeFormData({
            ...formdata,
            error: true,
            sent: false,
            loading: false,
          });
        }
      );
  };

  return (
    <form className="contact-form" onSubmit={handleSubmit}>
      <h4 className="content-title">Message Me</h4>
      <div className="row">
        <div className="col-12 col-md-6 form-group">
          <input
            className="form-control"
            type="text"
            name="name"
            placeholder="Name"
            value={formdata.name}
            onChange={handleName}
            required
            spellCheck="false"
          />
        </div>

        <div className="col-12 col-md-6 form-group">
          <input
            className="form-control"
            type="email"
            name="email"
            placeholder="Email"
            value={formdata.email}
            onChange={handleEmail}
            spellCheck="false"
            required
          />
        </div>

        <div className="col-12 form-group">
          <input
            className="form-control"
            type="text"
            name="subject"
            placeholder="Subject"
            value={formdata.subject}
            onChange={handleSubject}
            spellCheck="false"
            required
          />
        </div>

        <div className="col-12 form-group form-message">
          <textarea
            className="form-control"
            id="contact-form-msg"
            name="message"
            placeholder="Message"
            value={formdata.message}
            onChange={handleMessage}
            rows="5"
            spellCheck="false"
            required
          ></textarea>
        </div>

        <div className="col-12 form-submit">
          <button
            disabled={formdata.loading ? true : false}
            className="btn button-main button-scheme submit-button"
            id={
              formdata.loading
                ? "submit-button-loading"
                : "submit-button-default"
            }
            type="submit"
          >
            {formdata.loading ? "Waiting..." : "Submit"}
          </button>

          <p
            className={
              "submit-success-msg " +
              (formdata.sent && "msg-show ") +
              (formdata.error && "submit-error")
            }
          >
            {formdata.error
              ? "For Some Reason message can't be send!!!"
              : "Message has been successfully sent!"}
          </p>
        </div>
      </div>
    </form>
  );
}

export default ContactForm;
