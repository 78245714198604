import React from "react";
import "./Contactus.css";
import { ContactData } from "../../parseJson";
import ContactForm from "./ContactForm";
import { FaExclamationCircle, FaLinkedin } from "react-icons/fa";
import { MdEmail } from "react-icons/md";

function Contactus() {
  return (
    <div className="contactus main-section container-fluid" id="contactme">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading-container">
              <p className="heading-subtitle">{ContactData.subtitle}</p>
              <h2 className="heading-title">{ContactData.title}</h2>
            </div>
          </div>
        </div>
        <div className="contact-section single-section">
          <div className="row">
            <div className="main-section-content col-12 col-lg-7">
              <ContactForm />
            </div>

            <div className="main-section-content col-12 col-lg-5">
              <div className="contact-info d-flex flex-column align-items-center">
                <h4 className="content-title">
                  {ContactData.contact_info.title}
                </h4>
                <p className="info-description">
                  {ContactData.contact_info.subtitle}
                </p>
                <ul className="list-unstyled list-info">
                  <li>
                    <div className="media align-items-center">
                      <span className="info-icon">
                        <i className="info-logo">
                          <FaExclamationCircle />
                        </i>
                      </span>
                      <div className="media-body info-details">
                        <h6 className="info-type">
                          {ContactData.contact_info.name.title}
                        </h6>
                        <span className="info-value">
                          {ContactData.contact_info.name.description}
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media align-items-center">
                      <span className="info-icon">
                        <a
                          href={ContactData.contact_info.linkedin.link}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <i className="info-logo">
                            <FaLinkedin className="icon-class" />
                          </i>
                        </a>
                      </span>
                      <div className="media-body info-details">
                        <h6 className="info-type">
                          {ContactData.contact_info.linkedin.title}
                        </h6>
                        <span className="info-value">
                          <a
                            href={ContactData.contact_info.linkedin.link}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {ContactData.contact_info.linkedin.description}
                          </a>
                        </span>
                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="media align-items-center">
                      <span className="info-icon">
                        <a
                          href={
                            "mailto:" + ContactData.contact_info.mail.mailid
                          }
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <i className="info-logo">
                            <MdEmail className="icon-class" />
                          </i>
                        </a>
                      </span>
                      <div className="media-body info-details">
                        <h6 className="info-type">
                          {ContactData.contact_info.mail.title}
                        </h6>
                        <span className="info-value">
                          <a
                            href={
                              "mailto:" + ContactData.contact_info.mail.mailid
                            }
                            rel="noopener noreferrer"
                            target="_blank"
                          >
                            {ContactData.contact_info.mail.description}
                          </a>
                        </span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contactus;
