import React, { useState } from "react";
import Card from "../card/Card";
import "./Portfolio.css";
import { PortfolioData } from "../../parseJson";

function Portfolio() {
  const [portfolioNav, handlePortNav] = useState(
    PortfolioData.projects[PortfolioData.active].data
  );
  const [portfolioActive, handlePortflioActive] = useState(
    PortfolioData.active
  );

  return (
    <div className="portfolio main-section container-fluid" id="portfolio">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-align-center">
            <div className="heading-container">
              <p className="heading-subtitle">{PortfolioData.subtitle}</p>
              <h2 className="heading-title">{PortfolioData.title}</h2>
            </div>
          </div>
        </div>

        <div className="main-section-content row">
          <div className="col-12">
            <ul className="list-inline project-nav">
              {Object.keys(PortfolioData.projects).map((project) => {
                return (
                  <li
                    className={
                      "list-inline-item project-nav-item " +
                      (project === portfolioActive && "highlight")
                    }
                    onClick={() => {
                      handlePortflioActive(project);
                      handlePortNav(PortfolioData.projects[project].data);
                    }}
                  >
                    {PortfolioData.projects[project].title}
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="row row-cols-1 row-cols-md-2">
            {portfolioNav.map((project) => {
              return (
                <div class="col mb-4">
                  <div class="card h-100">
                    <Card data={project} />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Portfolio;
