import React from "react";
import { FaInstagram, FaLinkedin } from "react-icons/fa";
import "./Home.css";
import { HomeData } from "../../parseJson";
import Typist from "react-typist";
import TypistLoop from "react-typist-loop";

function Home() {
  return (
    <section className="section d-flex" id="home">
      <div className="container h-100">
        <div className="row h-100 align-items-center justify-content-center">
          <div className="col-12 col-lg-8 text-center">
            <h1 className="section__subtitle">
              <span>👋🏻 I'm&nbsp;</span>
            </h1>
            <h1 className="section__subtitle">
              <span className="section__subtitle--name"> {HomeData.title}</span>
            </h1>
            <h4 className="section__subtitle">
              <span>
                <TypistLoop interval={0}>
                  {HomeData.subtitle.map((text) => (
                    <Typist key={text} startDelay={100} avgTypingDelay={30}>
                      {text}
                      <Typist.Backspace count={text.length} delay={2000} />
                    </Typist>
                  ))}
                </TypistLoop>
              </span>
            </h4>
            <ul className="social-icon-list list-unstyled list-inline">
              <li className="list-inline-item">
                <a
                  href={HomeData.icon.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaInstagram />
                  </i>
                </a>
              </li>
              <li className="list-inline-item">
                <a
                  href={HomeData.icon.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i>
                    <FaLinkedin />
                  </i>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
