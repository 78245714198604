import React from "react";
import Skillcard from "../skillcard/Skillcard";
import { ResumeData } from "../../parseJson";
import "./Resume.css";

function Resume() {
  const checkButton = (data) => {
    if (data.button !== undefined) {
      return (
        <div className="btn-div">
          <a href={data.button.link} target="_blank" rel="noopener noreferrer">
            <button
              type="button"
              className="btn btn-outline-primary btn-custom"
            >
              {data.button.title}
            </button>
          </a>
        </div>
      );
    } else return <></>;
  };
  return (
    <div className="main-section container-fluid resume" id="resume">
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="heading-container">
              <p className="heading-subtitle">{ResumeData.subtitle}</p>
              <h2 className="heading-title">{ResumeData.title}</h2>
            </div>
          </div>
        </div>

        <div className="row resume-sec">
          {ResumeData.profile.map((data) => {
            return (
              <div className="col-12 col-md-6">
                <div className="col-section main-section-content">
                  <h3 className="col-sec-title">{data.title}</h3>
                  {data.sections.map((section) => {
                    return (
                      <div className="resume-sec-item">
                        <span className="sec-item-arrow"></span>
                        <h5 className="sec-item-title">{section.title}</h5>
                        <span className="sec-item-details">
                          {section.subtitle}
                        </span>
                        <p className="sec-item-description">
                          {section.description}
                        </p>
                        {checkButton(section)}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>

        {/* Skills */}
        <div className="skill-sec">
          <div className="row">
            <div className="col-12">
              <div className="heading-container">
                <p className="heading-subtitle">{ResumeData.skill.subtitle}</p>
                <h2 className="heading-title">{ResumeData.skill.title}</h2>
              </div>
            </div>
          </div>

          {ResumeData.skill.skillcards.map((skillcard) => {
            return (
              <div className="row">
                <div className="sec-single-item col-12">
                  <Skillcard data={skillcard} />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Resume;
