import React from "react";
import { InterestsData, ResumeData } from "../../parseJson";
import Card from "../card/Card";
import "./Interests.css";

function Interests() {
  return (
    <div className="interests main-section container-fluid" id="interests">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 text-align-center">
            <div className="heading-container">
              <p className="heading-subtitle">{InterestsData.subtitle}</p>
              <h2 className="heading-title">{InterestsData.title}</h2>
            </div>
          </div>
        </div>

        <div className="main-section-content row">
          <div className="row row-cols-1">
            {InterestsData.interests.map((interest) => {
              return (
                <div class="col mb-4">
                  <div class="card h-100">
                    <Card data={interest} interest />
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Interests;
